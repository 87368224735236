<template>
  <b-form-group label="Detalhes do Aluguel" label-for="rent-details">
    <b-card class="mb-2">
      <b-row>
        <b-col>
          <b-form-group label="Cliente">
            <b-input
              class="border-0"
              readonly
              :value="toy.rent.customer.name"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Brinquedo">
            <b-input class="border-0" readonly :value="toy.name" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Período">
            <b-input
              class="border-0"
              readonly
              :value="`${toy.rent.period.time} min - ${formatCurrency(
                toy.rent.period.price
              )}`"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group v-if="delay" label="Atraso">
            <b-input class="border-0" readonly :value="`${delay} minuto(s)`" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group label="Tempo Extra">
        <b-textarea class="border-0" readonly :value="extraTime" no-resize />
      </b-form-group>

      <b-form-group v-if="!hideTotal" label="Valor Total">
        <b-input class="border-0" readonly :value="totalAmount" />
      </b-form-group>
    </b-card>
  </b-form-group>
</template>

<script>
import { formatCurrency } from '@/helpers/numbers'
import { isEmpty } from 'lodash/fp'
import {
  calculateRentAmount,
  calculatePausedTime,
  calculateExtraTime,
  isPaused
} from '@/helpers/rents'
import { differenceInMinutes } from 'date-fns'
import { mapState } from 'vuex'

export default {
  name: 'ToyRentDetails',
  filters: {
    formatCurrency
  },
  props: {
    toy: {
      type: Object,
      required: true
    },
    delay: {
      type: Number,
      default: null
    },
    hideTotal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rentAmount: 0,
      productsAmount: 0,
      elapsedTime: 0
    }
  },
  computed: {
    ...mapState('store', ['currentStore']),
    extraTime() {
      return this.toy.rent.extraTime
        ?.map(({ minutes }) => `+ ${minutes} minutos`)
        .join('\n')
    },
    totalAmount() {
      return formatCurrency(this.rentAmount + this.productsAmount)
    },
    isPaused() {
      return isPaused(this.toy.rent)
    },
    hasProducts() {
      return this.toy.rent?.product?.length > 0
    }
  },
  watch: {
    'toy.rent': {
      handler() {
        this.setElapsedTime()
        this.setAmount()
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.setElapsedTime()
    this.setAmount()
    this.intervalId = setInterval(() => {
      this.setElapsedTime()
      this.setAmount()
    }, 1000)
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    formatCurrency(a) {
      return formatCurrency(a)
    },
    setElapsedTime() {
      if (isEmpty(this.toy.rent)) {
        return
      }

      const { createdAt, pausedAt } = this.toy.rent
      const fromDate = this.isPaused ? new Date(pausedAt) : new Date()
      const pausedTime = calculatePausedTime(this.toy.rent)
      const extraTime = calculateExtraTime(this.toy.rent)

      const elapsedTime =
        differenceInMinutes(fromDate, new Date(createdAt)) -
        pausedTime -
        extraTime
      this.elapsedTime = elapsedTime <= 0 ? 0 : elapsedTime
    },
    setAmount() {
      if (isEmpty(this.toy.rent)) {
        return
      }

      const { toy, elapsedTime, hasProducts, currentStore } = this
      const { delayFeePerMinute = 1, toleranceMinutes = 2 } = currentStore
      const { rent } = toy

      const { rentAmount, productsAmount } = calculateRentAmount({
        rent,
        elapsedTime,
        hasProducts,
        delayFeePerMinute,
        toleranceMinutes
      })

      this.rentAmount = rentAmount
      this.productsAmount = productsAmount
    }
  }
}
</script>
